<template>
  <div>
    <template v-if="row">
      <legend
        class="v-label theme--light mb-n2"
        style="left: 0px; right: auto; position: relative;"
      >
        {{ label }}
      </legend>
    </template>
    <v-radio-group
      v-model="radioGroup"
      color="primary"
      :column="column"
      :row="row"
      :label="row ? '' : label"
    >
      <v-radio
        v-for="item in items"
        :key="item.id"
        :label="item[itemLabel]"
        :value="item[itemValue]"
        @click="onClick"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'BaseRadio',
  props: {
    column: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => [],
      require: true
    },
    itemLabel: {
      type: String,
      default: '',
      require: true
    },
    itemValue: {
      type: String,
      default: '',
      require: true
    },
    label: {
      type: String,
      default: ''
    },
    row: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      selectedValue: ''
    };
  },
  computed: {
    radioGroup: {
      get() {
        return this.value;
      },
      set(value) {
        this.selectedValue = value;
      }
    }
  },
  methods: {
    onClick() {
      this.$emit('change', this.selectedValue);
    }
  }
};
</script>
