const THUMBNAIL_SIZE = 250;

class FileReaderSync extends FileReader {
  constructor() {
    super();
  }

  #readAs(blob, context) {
    return new Promise((resolve, reject) => {
      super.addEventListener('load', ({ target }) => resolve(target.result));
      super.addEventListener('error', ({ target }) => reject(target.error));
      super[context](blob);
    });
  }

  readAsDataURL(blob) {
    return this.#readAs(blob, 'readAsDataURL');
  }
}

async function imageToUrl (file, imageToUrl) {
  const image = new Image();
  const promise = new Promise(resolve => {
    image.onload = () => {
      resolve();
    };
    image.src = file;
  });
  await promise;
  return resizeImage(image, imageToUrl);
}

function resizeImage(imageObj, thumbnailSize) {
  let width, height;
  if (imageObj.width > imageObj.height) {
    const ratio = imageObj.height / imageObj.width;
    width = thumbnailSize;
    height = thumbnailSize * ratio;
  } else {
    const ratio = imageObj.width / imageObj.height;
    width = thumbnailSize * ratio;
    height = thumbnailSize;
  }
  const canvas = document.createElement('canvas');
  canvas.id = 'canvas';
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.clearRect(0, 0, width, height);
    ctx.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height, 0, 0, width, height);
  }
  return canvas.toDataURL('image/jpeg');
}

export default {
  data: () => ({
    thumbnailSize: 250
  }),
  methods: {
    async imageToBase64 (selectedFile, thumbnailSize = THUMBNAIL_SIZE) {
      const image = await new FileReaderSync().readAsDataURL(selectedFile);
      return imageToUrl(image, thumbnailSize);
    }
  }
};
