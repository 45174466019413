<template>
  <v-form ref="form" v-model="valid">
    <v-container class="mb-6 mt-10" style="max-width: 1000px">
      <div class="text-start mb-5">プロフィール編集</div>
      <v-row>
        <v-col cols="12" md="12">
          <v-card
            class="outset py-10 px-10 rounded-xl"
            :style="styles.app"
            flat
          >
            <v-card-text class="text-center">
              <div style="display: inline-block; position: relative;">
                <v-avatar
                  :color="getProfileImg ? 'white' : 'blue-grey lighten-1'"
                  size="80"
                >
                  <img v-if="getProfileImg" :src="getProfileImg" />
                  <v-icon v-else color="white" size="60">mdi-account</v-icon>
                </v-avatar>
                <v-file-input
                  label="プロフィール画像選択"
                  filled
                  hide-input
                  prepend-icon="mdi-camera-plus"
                  style="position: absolute; top: 45px; right: -25px;"
                  accept="image/*"
                  @change="onChangeProfileImg"
                />
              </div>
              <div class="mt-3 caption">
                画像ファイル（JPG, PNG） 10MB以下<br />
                推奨サイズ(縦 × 横): 400 × 400
              </div>
            </v-card-text>
            <v-card-text>
              <base-text-field
                :label="'氏名（変更不可）'"
                :disabled="true"
                :rules="[maxLength(50) ,required]"
                :value="me.fullname"
              />

              <base-text-field
                :label="'メールアドレス（変更不可）'"
                :disabled="true"
                :rules="[maxLength(254) ,required]"
                :value="me.email"
              />

              <!-- <base-text-field
                :counter="20"
                :hint="'アプリ内で使用する名前になります'"
                :label="'表示用のニックネーム'"
                :persistent-hint="true"
                :rules="[maxLength(20) ,required]"
                :value="me.nickname"
                @input="onInput({ nickname: $event })"
              /> -->

              <div class="v-messages theme--light"><div class="v-messages__wrapper"></div></div>
              <base-radio
                :items="[{ id: gender.male, label: '男性' }, { id: gender.female, label: '女性' }]"
                :item-label="'label'"
                :item-value="'id'"
                :label="'性別'"
                :row="true"
                :value="userprofile.gender"
                @change="onInput({ gender: $event })"
              />

              <base-date-picker
                :close-on-content-click="false"
                :label="'誕生日'"
                :min-width="'auto'"
                :no-title="true"
                :offset-y="true"
                :readonly="true"
                :ref-name="'birthday'"
                :rules="[required]"
                :scrollable="true"
                :transition="'scale-transition'"
                :value="userprofile.birthday"
                @input="onInput({ birthday: $event })"
              />

              <div class="v-messages theme--light"><div class="v-messages__wrapper"></div></div>
              <base-text-area
                :counter="2000"
                :placeholder="'クエストの依頼者にアピールするための実績や経歴を記入しましょう'"
                :persistent-placeholder="true"
                :label="'自己紹介'"
                :outlined="true"
                :rules="[maxLength(2000)]"
                :value="userprofile.summary"
                @input="onInput({ summary: $event })"
              />
            </v-card-text>

            <base-button
              :class-name="'py-6 font-weight-bold white--text'"
              :color="'primary'"
              :disabled="!valid"
              @click="onUpdate"
            >
              <template v-slot:text>更新する</template>
            </base-button>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import crypto from '@/utils/crypto';
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseButton from '@/components/atoms/BaseButton';
import BaseDatePicker from '@/components/atoms/BaseDatePicker';
import BaseRadio from '@/components/atoms/BaseRadio';
import BaseTextArea from '@/components/atoms/BaseTextArea';
import BaseTextField from '@/components/atoms/BaseTextField';
import AuthProfileMixin from '@/mixins/AuthProfileMixin';
import FormRulesMixin from '@/mixins/FormRulesMixin';
import { userprofile } from '@/store/models/userprofile';
import { GENDER } from '@/assets/constants';
import { firestoreAuth } from '@/plugins/firebase_auth';
import { ROOT_AUTH_COLLECTION } from '@/assets/constants';

export default {
  name: 'Edit',
  components: {
    BaseButton,
    BaseDatePicker,
    BaseRadio,
    BaseTextArea,
    BaseTextField
  },
  mixins: [AppStyleMixin, AppMethodsMixin, AuthProfileMixin, FormRulesMixin],
  data() {
    return {
      valid: false,
      userprofile,
      gender: GENDER,
      profileImgPreview: null
    };
  },
  computed: {
    me() {
      return this.$store.getters['users/getMe'];
    },
    getProfileImg() {
      return this.$store.getters['getProfileImg'];
    }
  },
  methods: {
    onInput(userprofile) {
      this.userprofile = { ...this.userprofile, ...userprofile };
    },
    /** プロフィール更新 */
    async onUpdate() {
      this.$store.commit('showLoadingOverlay');
      try {
        await this.$store.dispatch('userprofiles/update', this.userprofile);
      } finally {
        this.$store.commit('hideLoadingOverlay');
      }
    },
    /** プロフィール画像アップロード */
    async onChangeProfileImg(selectedFile) {
      if (confirm('プロフィール画像を更新しますか?')) {
        // DBのプロフィール更新
        const formData = new FormData();
        formData.append('file', selectedFile);
        this.$store.dispatch('userprofiles/uploadProfileImg', formData);

        try {
          // プロフィール情報取得
          const doc = await firestoreAuth
            .collection(ROOT_AUTH_COLLECTION)
            .doc(this.me.company.code)
            .collection(this.me.token)
            .doc('profile')
            .get();

          // アイコンデータ書き換え
          let profileData = {};
          if (doc.data()) {
            let { value } = doc.data();
            profileData = JSON.parse(crypto.decrypt(value, this.me.company.code));
          }
          profileData.iconData = await this.imageToBase64(selectedFile);

          // プロフィール情報保存
          const cryptedData = crypto.encrypt(JSON.stringify(profileData), this.me.company.code);
          firestoreAuth
            .collection(ROOT_AUTH_COLLECTION)
            .doc(this.me.company.code)
            .collection(this.me.token)
            .doc('profile')
            .set({ value: cryptedData });

          // stateのプロフィール画像更新
          this.$store.commit('addProfileImg', { companyCode: this.me.company.code, token: this.me.token });
        } catch(e) {
          console.log(e);
          // Firestoreへの保存に失敗した場合は何もしない
        }
      }
    }
  },
  async mounted() {
    this.$store.commit('showLoadingOverlay');
    try {
      // ユーザープロフィール取得
      this.userprofile = await this.$store.dispatch('userprofiles/get');
    } finally {
      this.$store.commit('hideLoadingOverlay');
    }
  }
};
</script>
